@use "src/styles" as *;

.other-example-group {

    &__group {
        margin-block-start: 1.5rem;

        & + & {
            margin-block-start: 2rem;
            padding-block-start: 2rem;
            border-block-start: var(--line);
        }
    }

    &__title {
        @extend %heading-3;
    }

    &__dialog-button {
        margin-block-start: 1rem;
    }

    &__dialog {
        color: var(--c-black);
    }

    &__tooltip-example {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.5rem;
    }

    &__tooltip {
        font-size: 1.25rem;
        font-weight: var(--fw-bold);
    }

    &__tooltip-disclaimer {
        display: none;
        width: 100%;
        font-style: italic;

        @include touch-screen {
            display: block;
        }
    }

    &__tabs-navigation,
    &__tab-content,
    &__animation-example,
    &__animation-example-text  {
        margin-block-start: 1.5rem;
    }

    &__counter-example {
        font-size: 1.25rem;
        font-weight: var(--fw-bold);
    }
}
