@use "src/styles" as *;

.race-result-table {
    width: 100%;
    border-collapse: collapse;

    &__head {
        padding: 0 1.25rem;
        color: var(--c-grey);
        font-size: .875rem;


        th {
            text-align: left;
            padding: .5rem;
            font-weight: var(--fw-regular);
            text-transform: uppercase;

            &:first-child {
                padding-left: 1.25rem;
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {

                @include for-size(phone-only) {
                    display: none;
                }
            }

            &:last-child {
                padding-right: 1.25rem;
            }
        }
    }

    &__body {
        padding: 0 1.25rem;

        tr:nth-child(odd) {
            background-color: var(--c-grey-light);
        }

        td {
            text-align: left;
            padding: 1rem .5rem;

            &:first-child {
                padding-left: 1.25rem;
            }

            &:last-child {
                padding-right: 1.25rem;
            }
        }
    }

    &__row {

        td {

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {

                @include for-size(phone-only) {
                    display: none;
                }
            }
        }
    }

    &__driver-name {

        &--abbreviation {
            display: none;

            @include for-size(phone-only) {
                display: block;
            }
        }

        &--full {
            display: block;

            @include for-size(phone-only) {
                display: none;
            }
        }
    }

    &__constructor-name {
        color: var(--c-grey);
        font-weight: var(--fw-medium);
        text-transform: uppercase;
    }
}