@use "src/styles" as *;

$component: "modal-content";

.modal-content {

    &--is-positive &__icon-wrapper {
        background-color: var(--c-positive);
    }

    &--is-warning &__icon-wrapper {
        background-color: var(--c-warning);
    }

    &--is-negative &__icon-wrapper {
        background-color: var(--c-negative);
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        min-width: 2.5rem;
        margin-inline-end: 1rem;
        color: var(--c-white);
        background-color: var(--c-black);
    }

    &__title {
        @extend %heading-2;
    }

    &__content-wrapper {
        margin-block-start: 2rem;
    }
}
