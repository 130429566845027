@use "src/styles" as *;

.text-editor-page {

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5rem;
        margin-block-start: 2.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__text-editor {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }
    }
}
