@use "src/styles" as *;

.hook-example {
    display: flex;
    align-items: center;

    &__bird {
        height: 10rem;
        transition: translate .1s ease;

        &--did-jump {
            translate: 0 -50%;
        }

        @include rtl {
            scale: -1 1;
        }
    }

    &__hint {
        margin-inline-start: 1rem;
    }
}
