@forward "extends";
@forward "animations";
@forward "variables";

* {
    box-sizing: border-box;
}

:focus:not(:focus-visible) {
    outline: none;
}

html {
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    color: var(--c-black);
    background-color: var(--c-white);
}

h1 {
    @extend %heading-1;
}

h2 {
    @extend %heading-2;
}

h3 {
    @extend %heading-3;
}

p {
    margin: 0;
}

#app-root,
#portal-root {
    position: relative;
    z-index: 10;
}

#portal-root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}