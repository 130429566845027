@use "src/styles" as *;

.input-label {
    @extend %label;

    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    &__required-star {
        color: var(--c-negative);
    }

    &__additional-label {
        color: var(--c-grey);
    }
}
