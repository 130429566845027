.race-weekend-info {

    &__previous-button {
        margin: 1rem 1.25rem 0;
    }

    &__tabs-navigation {
        margin: 1rem 1.25rem 0;
        padding-top: 1rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__tab-content {
        margin: 1rem 0;
    }

    &__tab-null-state {
        margin: 0 1.25rem;
    }
}