.picture {
    display: block;
    width: fit-content;
    max-width: 100%;
    overflow: clip;
    border-radius: var(--border-radius);

    &__image {
        width: 100%;
    }
}
