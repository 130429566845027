@use "src/styles" as *;

.push-notification-form {

    &__input {
        width: 100%;
        max-width: 24rem;

        & + & {
            margin-block-start: 1rem;
        }
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-block-start: 1.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__disclaimer {
        margin-block-start: 1rem;
        font-style: italic;
    }
}
