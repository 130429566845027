@use "src/styles" as *;

.settings {

    &__section {
        margin-top: 1rem;

        &:last-child {
            margin-top: 2rem;
        }
    }

    &__install-button-wrapper {
        @extend %flex-align-center;

        width: 100%;
        height: 2.5rem;

        @include for-size(tablet-portrait-up) {
            display: block;
            width: auto;
        }
    }

    &__sub-title {
        margin: 0;
        color: var(--c-grey);
        font-size: .875rem;
    }

    &__link {
        color: var(--c-primary);
    }

    &__disclaimer {
        font-size: .875rem;
        font-style: italic;
    }
}