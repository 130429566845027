%heading {
    margin: 0;
    font-weight: var(--fw-bold);
    color: var(--c-black);
}

%heading-1 {
    @extend %heading;

    font-size: 2rem;
    line-height: 1.25;
}

%heading-2 {
    @extend %heading;

    font-size: 1.5rem;
    line-height: 1.5;
}

%heading-3 {
    @extend %heading;

    font-size: 1.25rem;
    line-height: 1.5;
}

%label {
    font-size: .875rem;
    font-weight: var(--fw-bold);
    color: var(--c-grey);
}