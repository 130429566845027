@use "src/styles" as *;

.tooltip-wrapper {

    &__tooltip {
        --tooltip-x: 0px;
        --tooltip-y: 0px;
        --tooltip-offset-x: -50%;
        --tooltip-offset-y: calc(-100% - .5rem);

        position: absolute;
        top: var(--tooltip-y);
        left: var(--tooltip-x);
        padding-block: .5rem;
        padding-inline: 1rem;
        color: var(--c-white);
        pointer-events: none;
        border-radius: var(--border-radius);
        background-color: var(--c-black);
        transition: scale .2s ease, opacity .1s ease;
        will-change: left, top;
        transform-origin: bottom;
        translate: var(--tooltip-offset-x) var(--tooltip-offset-y);
        scale: .5;
        opacity: 0;

        &::after {
            @include square(.5rem);

            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            background-color: inherit;
            translate: -50% 50%;
            rotate: -45deg;
        }

        @include touch-screen {
            display: none;
            visibility: hidden;
        }

        &--is-fixed {
            position: fixed;
        }

        &--is-revealed {
            scale: 1;
            opacity: 1;
        }
    }
}
