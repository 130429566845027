.table-skeletons {

    &__row {
        width: 100%;
        height: 3.125rem;
        border-radius: 0;

        &:first-child {
            height: 2.0625rem;
        }

        &:nth-child(odd) {
            background-color: var(--c-white);
        }
    }
}