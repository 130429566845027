@use "src/styles" as *;

.text-editor-type-selector {
    position: relative;

    &__toggle-button {
        @extend %button--no-background;

        position: relative;
        padding-inline: 0;
        text-transform: none;
        color: currentColor;
    }

    &__toggle-icon {
        width: 1rem;
    }

    &__option-list {
        position: absolute;
        inset-block-start: 100%;
        inset-inline-start: 0;
        margin: 0;
        padding: 0;
        border: var(--line);
        background-color: var(--c-white);
    }

    &__option-item {
        list-style-type: none;

        & + & {
            border-block-start: inherit;
        }
    }

    &__option-button {
        @extend %button--no-background;

        justify-content: flex-start;
        width: 100%;
        padding: .75rem;
        text-transform: none;
        color: currentColor;
        border-radius: 0;

        &:enabled:hover,
        &:enabled:focus {
            color: var(--c-always-white);
            background-color: var(--c-primary);
        }

        &--paragraph {
            font-size: var(--text-editor-fs-paragraph);
            font-weight: var(--text-editor-fw-paragraph);
        }

        &--heading {
            font-size: var(--text-editor-fs-heading);
            font-weight: var(--text-editor-fw-heading);
        }
    }
}
