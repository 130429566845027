@use "src/styles" as *;

.tab {

    &__title {
        @extend %heading-3;
    }

    &__description {
        margin-block-start: 1rem;
    }
}
