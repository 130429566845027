@use "src/styles" as *;

$component: "checkbox";

.#{$component} {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: var(--fw-bold);
    color: var(--c-black);
    cursor: pointer;

    &--is-disabled {
        opacity: .5;
        pointer-events: none;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus:not(:focus-visible) ~ .#{$component}__label {
            text-decoration: none;
        }

        &:focus ~ .#{$component}__label {
            text-decoration: underline;
        }

        &:checked ~ .#{$component}__box {

            &::after {
                scale: 1;
            }

            .#{$component}__icon {
                opacity: 1;
            }
        }
    }

    &__box {
        @extend %flex-align-center;
        @include square(1.25rem);

        position: relative;
        overflow: clip;
        border-radius: .25rem;
        border: var(--line);

        &::after {
            @include square(1.25rem);

            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            background-color: var(--c-primary);
            translate: -50% -50%;
            scale: 0;
            transition: scale .1s ease;
        }
    }

    &__icon {
        position: relative;
        z-index: 20;
        width: .75rem;
        color: var(--c-white);
        opacity: 0;
        transition: opacity .1s ease;
    }

    &__label {
        margin-inline-start: .75rem;
    }
}
