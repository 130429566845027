$component: "language-selector";

.#{$component} {

    &__label-wrapper {
        display: flex;
        align-items: baseline;
        gap: .5rem;
    }

    &__label {
        margin-block-end: 0;
    }

    &__option-wrapper {
        display: flex;
        border-radius: var(--border-radius);
        border: var(--line);

        &:focus-within {
            outline: var(--focus-ring);
        }
    }

    &__option {
        padding-block: .125rem;
        padding-inline: .5rem;
        font-weight: var(--fw-bold);
        cursor: pointer;

        & + & {
            border-inline-start: var(--line);
        }
    }

    &__option-input {
        position: absolute;
        opacity: 0;
        z-index: 10;

        &:checked ~ .#{$component}__option-label {
            color: var(--c-primary);
        }
    }

    &__option-label {
        position: relative;
        z-index: 20;
        color: var(--c-black);
    }
}
