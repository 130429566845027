@use "src/styles" as *;

.chat-header {

    &__title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
    }

    &__title {
        @extend %heading-3;
    }

    &__controls-wrapper {
        display: flex;
        gap: .5rem;
    }

    &__control-button {
        @include square(3rem);

        padding: 0;
    }

    &__settings-form {
        margin-block-start: 1rem;
        padding-block-start: 1rem;
        border-block-start: var(--line);
    }
}
