@use "src/styles" as *;

.app-badge-buttons {

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__disclaimer {
        margin-block-start: 1.5rem;
        font-style: italic;

        & + & {
            margin-block-start: 1rem;
        }
    }
}
