@use "src/styles" as *;

.cache-buster {
    display: flex;
    justify-content: center;
    position: fixed;
    inset-block-end: 0;
    inset-inline-start: 0;
    z-index: 10;
    width: 100%;
    padding: 1.5rem;
    translate: 0 100%;
    transition: translate .3s ease;

    &--is-revealed {
        translate: 0 0;
    }

    &__card {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        border: 1px solid var(--c-primary);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
            padding-block: .75rem;
            padding-inline: 1.5rem;
        }
    }

    &__description {
        font-weight: var(--fw-bold);
    }

    &__button {
        margin-block-start: 1rem;
        padding-block: .75rem;
        padding-inline: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
            margin-inline-start: 1rem;
        }
    }
}
