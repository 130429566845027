@keyframes spin {
    to { rotate: 1turn; }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes skeleton-load {
    from { translate: -100% 0; }
    to { translate: 100% 0; }
}
