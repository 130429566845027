@use "src/styles" as *;

.chat-message-form {
    display: flex;
    width: 100%;

    &__input {
        width: 100%;
    }

    &__send-button {
        margin-inline-start: 1rem;

        @include rtl {
            scale: -1 1;
        }
    }
}
