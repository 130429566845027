@use "src/styles" as *;

.media-example-group {

    &__group {
        margin-block-start: 1.5rem;

        & + & {
            margin-block-start: 2rem;
            padding-block-start: 2rem;
            border-block-start: var(--line);
        }
    }

    &__title {
        @extend %heading-3;
    }

    &__figure {
        max-width: 40rem;
        margin-block-start: 1.5rem;
    }

    &__picture {
        border: var(--line);
    }

    &__video {
        width: fit-content;
        margin-block-start: 1.5rem;
    }
}
