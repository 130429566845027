.text-input {
    display: block;

    // Fix for native datepicker on iOS Safari
    [type="date"] {
        display: block;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 3.375rem;
    }
}
