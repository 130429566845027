@use "src/styles" as *;

.range-input-bar {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: clip;
    cursor: pointer;
    border-radius: var(--border-radius);
    border: var(--line);
    background-color: var(--c-white);

    &--is-disabled {
        --c-accent: var(--c-grey);

        pointer-events: none;
    }

    &__track {
        --range-input-track-width: calc(var(--range-input-width) * var(--range-input-track-size));

        position: relative;
        z-index: 10;
        width: var(--range-input-track-width);
        height: 100%;
        background-color: var(--c-accent);
        transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-min)));

        @include rtl {
            transform: translateX(negative(calc(#{var(--range-input-width)} * var(--range-input-min))));
        }

        &--is-inverted {
            width: calc(var(--range-input-width) - var(--range-input-track-width));
            transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-max)));

            @include rtl {
                transform: translateX(negative(calc(#{var(--range-input-width)} * var(--range-input-max))));
            }
        }
    }
}
