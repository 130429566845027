@use "src/styles" as *;

.button-example-group {

    &__button {

        &--has-logo {
            @extend %button--no-background;

            border: 2px solid var(--c-primary);
        }

        &--warning {
            @extend %button--warning;
        }

        &--delete {
            @extend %button--delete;
        }

        &+& {
            margin-block-start: 2rem;
        }
    }
}