// Dark mode
$dark-mode-enabled: true;

@mixin dark-mode-variables {
    --c-white-rgb: 24, 24, 24;
    --c-black-rgb: 255, 255, 255;

    --c-grey-light-rgb: 0, 0, 0;
    --c-grey-rgb: 241, 241, 241;
}

:root {
    --c-primary-rgb: 255, 87, 88;
    --c-primary: rgb(var(--c-primary-rgb));
    --c-primary-dark-rgb: 193, 17, 52;
    --c-primary-dark: rgb(var(--c-primary-dark-rgb));

    --c-secondary-rgb: 30, 144, 255;
    --c-secondary: rgb(var(--c-secondary-rgb));
    --c-secondary-dark-rgb: 30, 144, 255;
    --c-secondary-dark: rgb(var(--c-secondary-dark-rgb));

    --c-white-rgb: 255, 255, 255;
    --c-white: rgb(var(--c-white-rgb));
    --c-always-white-rgb: 255, 255, 255;
    --c-always-white: rgb(var(--c-always-white-rgb));

    --c-grey-light-rgb: 241, 241, 241;
    --c-grey-light: rgb(var(--c-grey-light-rgb));
    --c-grey-rgb: 127, 127, 127;
    --c-grey: rgb(var(--c-grey-rgb));

    --c-black-rgb: 24, 24, 24;
    --c-black: rgb(var(--c-black-rgb));
    --c-always-black-rgb: 24, 24, 24;
    --c-always-black: rgb(var(--c-always-black-rgb));
    --c-full-black-rgb: 0, 0, 0;
    --c-full-black: rgb(var(--c-full-black-rgb));

    --c-positive-rgb: 1, 188, 156;
    --c-positive: rgb(var(--c-positive-rgb));
    --c-negative-rgb: 255, 51, 52;
    --c-negative: rgb(var(--c-negative-rgb));
    --c-warning-rgb: 255, 153, 0;
    --c-warning: rgb(var(--c-warning-rgb));
    --c-warning-dark-rgb: 203, 120, 0;
    --c-warning-dark: rgb(var(--c-warning-dark-rgb));

    --c-accent: var(--c-primary);

    --c-gold: #efbf04;

    --f-primary: system-ui, sans-serif;

    --fw-regular: 400;
    --fw-medium: 500;
    --fw-bold: 700;

    --wrapper-width: 80rem;
    --wrapper-padding: 1.25rem;

    --line: 1px solid var(--c-black);
    --border-radius: .5rem;
    --box-shadow: 0 2px 4px 0 rgba(var(--c-black-rgb), .2);
    --menu-box-shadow: 0px -4px 8px 0px rgba(var(--c-grey-light-rgb), .75);
    --focus-ring: 2px solid var(--c-primary);

    @if $dark-mode-enabled {
        &[data-color-scheme="dark"] {
            @include dark-mode-variables;
        }
    }
}

::backdrop {
    --c-full-black-rgb: 0, 0, 0;
    --c-full-black: rgb(var(--c-full-black-rgb));
}