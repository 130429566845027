.error-message {
    margin: 0;
    padding-block: .5rem;
    padding-inline-start: 1rem;
    border-inline-start: .25rem solid var(--c-negative);

    &__text {
        display: flex;
        align-items: baseline;
        list-style-type: none;

        &::before {
            content: attr(data-status);
            margin-inline-end: .5rem;
            font-weight: var(--fw-bold);
            color: var(--c-negative);
        }
    }
}
