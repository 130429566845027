@use "src/styles" as *;

.search-input {

    &__wrapper {
        display: flex;
        overflow: clip;
        border-radius: var(--border-radius);
        border: var(--line);

        &--has-error {
            border: 2px solid var(--c-negative);
        }

        &--has-search-results {
            border-end-start-radius: 0;
            border-end-end-radius: 0;
        }

        &--has-multiple-values {
            border-start-start-radius: 0;
            border-start-end-radius: 0;
        }

        &:focus-within {
            border-color: var(--c-primary);
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;

        padding: 1rem;
        background-color: var(--c-white);
    }

    &__icon {
        color: currentColor;
    }

    &__input {
        border: none;

        &--has-icon {
            border-radius: 0;
            appearance: textfield;
        }
    }
}
