.icon-button {
    display: flex;
    align-items: center;
    gap: .625rem;

    &--align-right {
        flex-direction: row-reverse;
    }

    &__icon {
        display: block;
    }
}
