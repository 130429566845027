.calendar-list-item {
    list-style-type: none;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--c-grey-light);

    &+& {
        margin-top: 1rem;
    }

    &:last-child {
        border-bottom: none;
    }

    &__link {
        color: var(--c-black);
        text-decoration: none;
    }

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__info {
        padding: 1rem;
    }

    &__flag {
        margin-right: .5rem;
        width: 1.5rem;
        line-height: 1.05rem;
        border: 1px solid var(--c-grey-light);
    }

    &__title {
        margin: 1.5rem 0 0;
    }

    &__sub-title {
        margin: 1rem 0 0;
        color: var(--c-grey);
        font-size: .875rem;
    }

    &__date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        min-width: 6rem;
        background-color: var(--c-grey-light);

        &--is-active {
            background-color: #ff5758;
            color: #ffffff;
        }
    }

    &__month {
        text-transform: uppercase;
    }

    &__day {
        margin-top: .5rem;
        font-size: 2rem;
        font-weight: var(--fw-medium);
    }
}