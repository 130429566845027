.input-example-group {

    &__input {
        display: block;
        max-width: 26rem;

        & + & {
            margin-block-start: 2rem;
        }
    }

    &__select-input {
        position: relative;
        z-index: 10;
    }

    &__button {
        margin-block-start: 2rem;
    }
}
