@use "src/styles" as *;

.text-editor-controls {
    display: flex;
    align-items: center;
    gap: .25rem;

    &__type-selector {
        padding-inline-end: 1rem;
        border-inline-end: var(--line);
    }

    &__button {
        @extend %flex-align-center;
        @include square(2rem);

        padding: 0;
        color: currentColor;
        background-color: transparent;

        &:enabled:hover,
        &:focus {
            background-color: rgba(var(--c-black-rgb), .2);
        }
    }

    &__history-wrapper {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        gap: .25rem;
    }
}
