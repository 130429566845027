@use "src/styles" as *;

.chat-settings-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__user-field-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: stretch;
        }
    }

    &__input-wrapper {
        display: flex;
        width: 100%;
    }

    &__name-input {
        width: 100%;
    }

    &__name-field {
        border-start-end-radius: 0;
        border-end-end-radius: 0;
    }

    &__color-field {
        @include square(2.75rem);

        height: 100%;
        max-height: unset;
        padding: 0;
        overflow: clip;
        border-inline-start: none;
        border-start-start-radius: 0;
        border-end-start-radius: 0;
    }
}
