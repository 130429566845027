.driver-results-list-item {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &__flag {
        margin-right: .5rem;
        width: 1.5rem;
        line-height: 1.05rem;
        border: 1px solid var(--c-grey-light);
    }

    &__constructor {
        margin: .5rem 0 0 2.125rem;
        font-size: 1.125rem;
    }

    &__result {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__position-label,
    &__points-label {
        font-size: .875rem;
    }

    &__position,
    &__points {
        margin-left: .5rem;
        min-width: 2rem;
        font-size: 1.125rem;
        text-align: right;
    }

    &__points {
        font-weight: var(--fw-medium);
    }

    &__points-label,
    &__points {
        margin-top: .375rem;
    }
}