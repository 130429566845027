@use "src/styles" as *;

$component: "radio";

.#{$component} {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: var(--fw-bold);
    color: var(--c-black);
    cursor: pointer;

    &--is-disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus:not(:focus-visible) ~ .#{$component}__label {
            text-decoration: none;
        }

        &:focus ~ .#{$component}__label {
            text-decoration: underline;
        }

        &:checked  ~ .#{$component}__box::after {
            scale: 1;
        }
    }

    &__box {
        @extend %flex-align-center;
        @include circle(1.25rem);

        border: var(--line);

        &::after {
            @include circle(.875rem);

            content: "";
            background-color: var(--c-primary);
            scale: 0;
            transition: scale .1s ease;
        }
    }

    &__label {
        margin-inline-start: .75rem;
    }
}
