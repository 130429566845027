.select {
    width: 100%;
    cursor: pointer;
    font-weight: var(--fw-regular);
    background-color: var(--c-white);

    &:focus-within &__control {
        border-color: var(--c-primary) !important;
        box-shadow: none;
    }

    &--is-disabled {
        cursor: not-allowed !important;
        opacity: .5;
    }

    &--has-error {
        border: 2px solid var(--c-negative);
    }

    &__control {
        color: var(--c-black) !important;
        border-radius: 0 !important;
        border: var(--line) !important;
        background-color: var(--c-white) !important;

        &--is-focused {
            border: 1px solid var(--c-white) !important;
            box-shadow: none;
        }
    }

    &__input {
        padding-block: .75rem;
        padding-inline: 0;
        color: var(--c-black) !important;
    }

    &__placeholder {
        color: var(--c-black) !important;
    }

    &__indicator-separator {
        background-color: var(--c-black) !important;
    }

    &__indicator {
        color: var(--c-black) !important;
    }

    &__value-container {
        padding: .75rem !important;
    }

    &__single-value {
        color: var(--c-black) !important;
    }

    &__menu {
        background-color: var(--c-white) !important;
    }

    &__menu-list {
        max-height: 14rem !important;
    }

    &__option {
        color: var(--c-black) !important;

        &--is-focused {
            color: var(--c-always-white) !important;
            background-color: var(--c-primary) !important;
        }

        &--is-selected {
            color: var(--c-always-white) !important;
            background-color: var(--c-primary-dark) !important;
        }
    }
}
