.searchable-multi-value-input {

    &__label {
        margin-block-end: .625rem;
    }

    &__value-list {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        margin: 0;
        padding: 1rem;
        border-radius: var(--border-radius);
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        border: var(--line);
        border-block-end: none;
        background-color: var(--c-white);
    }

    &__value-item {
        list-style-type: none;
    }
}
