@use "src/styles" as *;

.dialog {
    width: 95%;
    max-width: 36rem;
    padding: 1.5rem;
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--c-white);
    box-shadow: var(--box-shadow);

    @include for-size(tablet-landscape-up) {
        padding: 2.5rem;
    }

    &[open],
    &[open]::backdrop {
        animation: fade-in .3s ease;
    }

    &--is-hidden[open],
    &--is-hidden[open]::backdrop {
        animation: fade-out .3s ease forwards;
    }

    &--has-interactive-backdrop::backdrop {
        cursor: pointer;
    }

    &::backdrop {
        background-color: rgba(var(--c-full-black-rgb), .5);
    }

    &__close-button {
        @extend %button--no-background;

        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 10;
        padding: 1.5rem;
        color: var(--c-primary);
    }

    &__close-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}
