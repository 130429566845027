@use "src/styles" as *;

.password-input {
    display: block;

    &__field-wrapper {
        display: flex;
        position: relative;
    }

    &__field {
        border-inline-end: none;
        border-start-end-radius: 0;
        border-end-end-radius: 0;
    }

    &__visibility-button {
        @extend %button;

        padding-block: 1rem;
        padding-inline: 1.5rem;
        color: var(--c-white);
        border-start-start-radius: 0;
        border-end-start-radius: 0;
        background-color: var(--c-primary);
    }

    &__visibility-icon {
        @include square(1.25rem);

        color: var(--c-white);
    }
}
