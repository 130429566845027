.calendar-list-skeletons {
    padding: 0 0 1rem;

    &__list-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 1rem;
        list-style-type: none;
        border-bottom: 1px solid var(--c-grey-light);


        &+& {
            margin-top: 1rem;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    &__info {
        padding: 1rem;
        width: 100%;
    }

    &__location {
        height: 1.175rem;
    }

    &__title {
        margin-top: 1.5rem;
        height: 2.25rem;
    }

    &__time-label {
        margin-top: 1rem;
        max-width: 20%;
        height: 1.0625rem;
    }

    &__time {
        margin-top: .25rem;
        max-width: 40%;
        height: 1.125rem;
    }

    &__dates {
        display: block;
        width: 10rem;
        height: auto;
        border-radius: 0;
    }
}