%button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-block: .75rem;
    padding-inline: 1.5rem;
    font: inherit;
    font-size: 1rem;
    font-weight: var(--fw-bold);
    line-height: 1;
    color: var(--c-always-white);
    cursor: pointer;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--c-primary);

    &:hover,
    &:focus {
        background-color: var(--c-primary-dark);
    }

    &:disabled {
        color: var(--c-white);
        cursor: not-allowed;
        background-color: var(--c-black);
    }
}

%button--no-background {
    background: none;

    &[href]:hover,
    &[href]:focus {
        background: none;
    }

    &:enabled:hover,
    &:enabled:focus {
        background: none;
    }
}

%button--transparent-background {
    @extend %button--no-background;

    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
        background-color: currentColor;
        opacity: .1;
    }

    &[href]:hover,
    &[href]:focus {

        &::after {
            opacity: .15;
        }
    }

    &:enabled:hover,
    &:enabled:focus {

        &::after {
            opacity: .15;
        }
    }
}

%button--delete {
    color: var(--c-always-black);
    background-color: var(--c-negative);

    &:hover,
    &:focus {
        background-color: rgba($color: var(--c-negative-rgb), $alpha: .85);
    }

    &:disabled {
        color: var(--c-white);
    }
}

%button--warning {
    color: var(--c-always-black);
    background-color: var(--c-warning);

    &:hover,
    &:focus {
        background-color: var(--c-warning-dark);
    }

    &:disabled {
        color: var(--c-white);
    }
}