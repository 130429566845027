.home-page {

    &__date {
        margin-block-start: 1rem;
    }

    &__introduction {
        margin-block-start: 1rem;
    }

    &__hook-example {
        margin-block-start: 6rem;
    }
}
