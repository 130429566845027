@use "src/styles" as *;

.public-apis-entry-card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    @include dark-mode {
        border: var(--line);
        box-shadow: none;
    }

    &__title {
        @extend %heading-3;
    }

    &__description {
        margin-block-start: 1rem;
    }

    &__property-list {
        display: flex;
        gap: 1rem;
        margin-block: 1rem 1.5rem;
        margin-inline: 0;
        padding: 0;
    }

    &__property-item {
        @extend %label;

        list-style-type: none;
    }

    &__url-wrapper {
        margin-block-start: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__url {
        color: var(--c-primary);

        &::after {
            @extend %click-extend;

            content: "";
        }
    }
}
