.menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--c-white);
    -webkit-box-shadow: var(--menu-box-shadow);
    -moz-box-shadow: var(--menu-box-shadow);
    box-shadow: var(--menu-box-shadow);

    &__logo {
        position: relative;
        height: 5rem;
        z-index: 100;
    }
}