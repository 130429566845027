@use "src/styles" as *;

.pwa-example-group {

    &__group {
        margin-block-start: 1.5rem;

        & + & {
            margin-block-start: 2rem;
            padding-block-start: 2rem;
            border-block-start: var(--line);
        }
    }

    &__title {
        @extend %heading-3;
    }

    &__push-notification-form,
    &__app-badge-buttons {
        margin-block-start: 1.5rem;
    }
}
