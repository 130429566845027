@use "src/styles" as *;

.public-apis-overview {

    &__search-form {
        display: flex;
        gap: 1.5rem;
    }

    &__search-input,
    &__search-button {
        height: 3rem;
    }

    &__search-button {
        margin-block-start: 1.75rem;
    }

    &__search-results {
        position: absolute;
        z-index: 10;
        width: 100%;
        background-color: var(--c-white);
        box-shadow: var(--shadow-medium);
    }

    &__results-wrapper {
         margin-block-start: 2.5rem;
    }

    &__results-title {
        font-size: 1.5rem;
    }

    &__error-message,
    &__no-results {
        margin-block-start: 1rem;
    }

    &__results-list {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.5rem;
        margin: 0;
        margin-block-start: 1rem;
        padding: 0;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include for-size(desktop-up) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    &__results-item {
        width: 100%;
        list-style-type: none;
    }
}
