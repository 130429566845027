@use "src/styles" as *;

.error-label {
    @extend %label;

    display: block;
    color: var(--c-negative);
    margin-left: 1.25rem;

    &:first-letter {
        text-transform: uppercase;
    }
}