@use "src/styles" as *;

.tabs-navigation-item {
    white-space: nowrap;

    &__link {
        @extend %label;

        display: flex;
        align-items: center;
        width: 100%;
        padding: .5rem 1rem;
        text-decoration: none;
        border: 1px solid transparent;
        border-radius: 1rem;

        &--is-active {
            color: var(--c-primary);
            border-color: currentColor;
        }

        &:hover,
        &:focus {
            color: var(--c-primary);
        }
    }
}