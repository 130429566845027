.input-label-wrapper {

    &__label {
        margin-block-end: .625rem;
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
