.loading {
    display: flex;
    align-items: center;

    &__spinner {
        color: var(--c-primary);
    }

    &__text {
        margin-inline-start: .5rem;
    }
}
