@use "src/styles" as *;

.skeleton {
    --skeleton-width: 100%;

    display: block;
    position: relative;
    width: var(--skeleton-width);
    height: 1em;
    overflow: clip;
    border-radius: var(--border-radius);
    background-color: var(--c-grey-light);

    &::after {
        content: "";
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right,
                transparent 0%,
                rgba(var(--c-white-rgb), .6) 40%,
                transparent 60%);
        animation: skeleton-load 2s ease infinite;

        @include rtl {
            scale: -1 1;
            animation-direction: reverse;
        }
    }
}