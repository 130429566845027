@use "src/styles" as *;

.navigation {

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0 1.25rem;

        @include for-size(tablet-landscape-up) {
            justify-content: space-around;
        }
    }

    &__item {
        padding: 0 1rem;
        list-style-type: none;
    }
}