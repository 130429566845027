.standings-list-item {

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .75rem 1.5rem;
        color: var(--c-black);
        text-decoration: none;
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__position {
        min-width: 1.5rem;
        font-size: 1.25rem;
        font-weight: var(--fw-medium);

        &--is-champion {
            font-weight: var(--fw-bold);
            color: var(--c-gold);
        }
    }

    &__flag {
        margin-left: 1rem;
        width: 1.5rem;
        line-height: 1.05rem;
        border: 1px solid var(--c-grey-light);
    }

    &__title-wrapper {
        margin-left: 1rem;
    }

    &__title {
        font-size: 1.25rem;
        font-weight: var(--fw-medium);
    }

    &__sub-title {
        margin-top: .25rem;
    }

    &__points {
        font-size: 1.5rem;
        font-weight: var(--fw-medium);

        &--is-champion {
            font-weight: var(--fw-bold);
            color: var(--c-gold);
        }
    }
}