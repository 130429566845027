.driver-standings-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__list-item {
        border-bottom: 1px solid var(--c-grey-light);

        &:last-child {
            border-bottom: none;
        }

        &--is-champion {
            border-bottom-color: var(--c-gold);
        }
    }
}