.burger {
    position: relative;
    z-index: 100;
    width: 2.5rem;
    height: 2rem;
    transform: rotate(0deg);
    will-change: transform;
    transition: .25s ease-in-out;
    cursor: pointer;

    &__line {
        position: absolute;
        left: 0;
        display: block;
        height: .25rem;
        width: 100%;
        background-color: var(--c-black);
        border-radius: var(--border-radius);
        opacity: 1;
        will-change: transform;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: .75rem;
        }

        &:nth-child(3) {
            top: .75rem;
        }

        &:nth-child(4) {
            top: 1.5rem;
        }

        &--is-open {

            &:nth-child(1) {
                top: .75rem;
                width: 0;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: .75rem;
                width: 0;
                left: 50%;
            }
        }
    }
}