@use "src/styles" as *;

.public-apis-entry-card-skeleton {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    @include dark-mode {
        border: var(--line);
        box-shadow: none;
    }

    &__title {
        @extend %heading-3;
    }

    &__description {
        margin-block-start: 1rem;
    }

    &__property-list {
        display: flex;
        gap: 1rem;
        margin-block: 1rem 1.5rem;
        margin-inline: 0;
        padding: 0;
    }

    &__property-item {
        width: 3rem;
        list-style-type: none;
        font-size: .875rem;
    }

    &__url {
        margin-block-start: auto;
    }
}
