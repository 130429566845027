@forward "button";
@forward "input";
@forward "typography";

%flex-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%click-extend {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
}

%draggable {
    border: var(--line);
    background-color: var(--c-always-white);
}

%cue {
    font-family: var(--f-primary);
    font-size: 1.25rem;
    font-weight: var(--fw-bold);
    color: var(--c-always-white);
    text-shadow: 1px 1px 2px var(--c-always-black);
    background-color: transparent;
}
