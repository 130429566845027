.calendar-list {
    margin: 0;
    padding: 0;

    &__item {
        scroll-margin-top: 3rem;
    }

    &__next-year-button {
        margin: 1rem 1rem .5rem;
    }
}