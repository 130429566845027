@use "src/styles" as *;

.text-editor-toggle {
    @extend %flex-align-center;
    @include square(2rem);

    padding: 0;
    color: currentColor;
    background-color: transparent;

    &:hover,
    &:focus-within {
        background-color: rgba(var(--c-black-rgb), .2);
    }

    &--is-active {
        color: var(--c-always-white);
        background-color: var(--c-primary);

        &:hover,
        &:focus-within {
            background-color: var(--c-primary-dark);
        }
    }
}
