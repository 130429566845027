@use "src/styles" as *;

.input {
    @extend %input;

    &--has-error {
        border: 2px solid var(--c-negative);
    }

    &::placeholder {
        color: var(--c-black);
        opacity: .5;
    }
}
