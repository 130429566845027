@use "src/styles" as *;

.page-header {
    position: sticky;
    top: 0;
    z-index: 20;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;
    background-color: var(--c-grey-light);
    will-change: transform;
    transform: translateY(0);
    transition: .15s ease-in-out;

    &--is-scrolling-down {
        transform: translateY(-100%);
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
    }

    &__previous-button {
        @extend %button--no-background;

        margin-right: .5rem;
        padding: .75rem;
        color: var(--c-black);
    }

    &__title {
        font-size: 1.5rem;
    }

    &__actions {
        display: flex;
    }

    &__settings-button {
        @extend %button--no-background;

        color: var(--c-black);
        padding: .75rem;
    }

    &__settings-button-icon {
        height: 1.5rem !important;
    }

    &__logo {
        margin-left: 1rem;
    }
}