@use "src/styles" as *;

.tabs-navigation {
    overflow-x: scroll;

    &__list {
        display: flex;
        gap: 1rem;
        margin: 0;
        padding: 0 0 .5rem 0;
    }

    &__item {
        list-style-type: none;
    }
}