@use "src/styles" as *;

.chat-event-user-change {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-block: .5rem;
    padding-inline: 1rem;
    font-weight: var(--fw-bold);
    color: var(--c-white);
    background-color: var(--c-black);

    &__old-name {
        color: var(--c-chat-old-color);
    }

    &__arrow {
        height: 1rem;

        @include rtl {
            scale: -1 1;
        }
    }

    &__new-name {
        font-weight: var(--fw-bold);
        color: var(--c-chat-new-color);
    }
}
