.chat-event-message {
    display: flex;
    flex-direction: column;
    min-width: 40%;
    max-width: 24rem;
    padding: 1rem;
    border-radius: var(--border-radius);
    border: var(--line);
    background-color: var(--c-chat-message);

    &__author {
        margin-block-end: .5rem;
        font-weight: var(--fw-bold);
        color: var(--c-chat-author);
    }

    &__date {
        margin-block-start: .5rem;
        font-size: .875rem;
        text-align: end;
        opacity: .75;
    }
}
