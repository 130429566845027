.chat-event-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 50vh;
    overflow: auto;

    &__list {
        margin: 0;
        padding: 1.5rem;
        overflow: inherit;
    }

    &__item {
        --c-chat-message: var(--c-white);

        display: flex;

        &--is-author {
            --c-chat-message: rgba(var(--c-primary-rgb), .25);

            justify-content: flex-end;
        }

        & + & {
            margin-block-start: .25rem;

            &--is-first {
                margin-block-start: 1.5rem;
            }
        }
    }

    &__scroll-button {
        position: absolute;
        inset-block-end: 1rem;
        inset-inline-start: 50%;
        padding-block: .5rem;
        padding-inline: 1rem;
        white-space: nowrap;
        pointer-events: none;
        transition: opacity .1s ease;
        opacity: 0;
        translate: -50% 0;

        &--is-visible {
            pointer-events: all;
            opacity: 1;
        }
    }
}
