.driver-results-list {

    &__driver-name {
        margin: 0 0 0 1rem;
    }

    &__list {
        margin: .5rem 0 0 0;
        padding: 0;
        list-style-type: none;
    }

    &__list-item {
        border-bottom: 1px solid var(--c-grey-light);

        &:last-child {
            border-bottom: none;
        }
    }
}