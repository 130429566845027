%input {
    width: 100%;
    margin: 0;
    padding: 1rem;
    font: inherit;
    font-weight: var(--fw-regular);
    color: var(--c-black);
    border-radius: var(--border-radius);
    border: var(--line);
    background-color: var(--c-white);

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &:focus {
        outline: none;
        border-color: var(--c-primary);
    }
}
