@use "src/styles" as *;

.text-editor {
    --text-editor-fs-paragraph: 1rem;
    --text-editor-fw-paragraph: var(--fw-regular);

    --text-editor-fs-heading: 1.5rem;
    --text-editor-fw-heading: var(--fw-bold);

    width: 100%;

    &__input-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    &__input {
        @extend %input;

        min-height: 12em;
        max-height: 24em;
        padding: 1rem;
        overflow-y: auto;
        resize: vertical;
        border-radius: var(--border-radius);
        border-start-start-radius: 0;
        border-start-end-radius: 0;
        border: var(--line);

        &--has-error {
            border: 2px solid var(--c-negative);
        }
    }

    &__controls {
        padding-block: .5rem;
        padding-inline: 1rem;
        border-radius: var(--border-radius);
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        border: var(--line);
        border-block-end: none;
    }
}
