@use "src/styles" as *;

.video {
    width: fit-content;
    max-width: 100%;
    overflow: clip;
    border: var(--line);
    border-radius: var(--border-radius);

    &::cue {
        @extend %cue;
    }

    &::backdrop {
        background-color: transparent;
        backdrop-filter: blur(.5rem);
    }

    &__download-wrapper {
        width: fit-content;
        padding: 1rem;
        border-radius: var(--border-radius);
        border: var(--line);
    }

    &__download-list {
        margin: 0;
        margin-block-start: .5rem;
        padding-inline-start: 1rem;
    }

    &__download-item {

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__download-link {
        font-weight: var(--fw-bold);
        color: var(--c-primary);
        text-decoration: none;
    }
}
