.animation-example {

    &__block-wrapper {
        position: relative;
        width: 20rem;
        aspect-ratio: 2 / 1;
        border: 1px dashed var(--c-black);
    }

    &__block {
        position: absolute;
        top: -.5rem;
        left: -.5rem;
        width: 1rem;
        height: 1rem;
        background-color: var(--c-primary);
    }

    &__input {
        width: 20rem;
        margin-block-start: 1rem;
    }
}
