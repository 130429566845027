@use "src/styles" as *;

.navigation-item {

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .5rem 1rem;
        text-decoration: none;
        color: var(--c-black);
        width: 5rem;

        @include for-size(tablet-landscape-up) {
            padding-top: 1rem;
        }

        &--is-active {
            color: var(--c-primary);
            border-top: .125rem solid var(--c-primary);
        }
    }

    &__icon {
        height: 1.5rem;
    }

    &__label {
        margin-top: .25rem;
        font-size: .75rem;
    }
}