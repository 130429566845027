.not-found-page {

    &__description {
        margin-block-start: 1rem;
    }

    &__link-button {
        margin-block-start: 1.5rem;
    }
}
