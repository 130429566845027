.chat-room {
    width: 100%;
    max-width: 32rem;
    border-radius: var(--border-radius);
    border: var(--line);

    &__header {
        padding: 1.5rem;
        border-block-end: var(--line);
    }

    &__footer {
        padding: 1.5rem;
        border-block-start: var(--line);
    }

    &__error-message {
        width: 100%;
        padding-block: 1rem;
        background-color: rgba(var(--c-negative-rgb), .25);
    }
}
