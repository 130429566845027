.searchable-value-input {
    position: relative;

    &__result-list {
        max-height: 22em;
        margin: 0;
        padding: 0;
        overflow: auto;
        border-radius: var(--border-radius);
        border-start-start-radius: 0;
        border-start-end-radius: 0;
        border: var(--line);
        border-block-start: none;
        background-color: var(--c-white);
    }

    &__result-option {

        & + & {
            border-block-start: var(--line);
        }
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
