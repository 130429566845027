@use "src/styles" as *;

.race-detail-info {

    &__label {
        @extend %label;

        margin: 0;
    }

    &__date {
        display: flex;
        justify-content: space-between;
    }
}